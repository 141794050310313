
::v-deep .v-dialog {
    border-radius 18px
    border: 2px solid $color-bnn
    overflow-x hidden
    // max-height fit-content
    // overflow-y auto
    margin 20px 0 0

    @media screen and (min-width: 768px) {
        margin 0
    }

    @media screen and (min-width: 1024px) {
        margin 0
    }
}

.pdp-modal-container {
    margin 0 auto !important
    width 100%
    max-width 100dvw
    min-width 100dvw
    position relative

    display flex
    flex-direction column


    @media screen and (min-width: 768px) {
        width 100%
        min-width 692px
        max-width 692px
    }

    @media screen and (min-width: 1024px) {
        width 100%
        max-width 1029px
        min-width 1029px
    }
}

.pdp-modal-content {
    width 100%
    height fit-content

    margin-top -40px
    min-height calc(100vh - 20px)

    @media screen and (min-width: 768px) {
        min-height: fit-content
    }
}

.btn-close {
    position sticky;
    top: 16px
    left: 100%

    width: 40px;
    height: 40px;

    margin-right: 16px

    background: #e8e8e8;
    // border: 1px solid rgba(#121212, 0.1);
    border-radius: 50% !important;

    z-index 10

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.56)
    }


}
